<template>
    <div>
        <el-collapse v-model="activeName" accordion v-show="!isHidden">
            <el-collapse-item v-for="(record, index) in records" :name="index" :key="index">
              <template slot="title">
                <span v-html="record.title" :style="titleStyle"></span>
              </template>
              <div v-html="record.content" :style="contentStyle"></div>
            </el-collapse-item>
        </el-collapse>
        <slot></slot>
    </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'

export default {
  name: 'a-accordion',
  mixins: [mixin, VisibleMixin],
  props: {
    source: {
      type: Object,
      default () {
        return {
          type: null,
          entityId: null,
          titleField: null,
          contentField: null
        }
      },
      editor: 'AccordionSource'
    },
    filters: {
      type: Array,
      editor: 'Filters'
    },
    limit: {
      type: Number,
      default: 100,
      description: 'Лимит записей'
    },
    titleStyle: {
      type: String,
      default: null,
      description: 'Стиль загаловка'
    },
    contentStyle: {
      type: String,
      default: null,
      description: 'Стиль контента'
    }
  },
  data () {
    return {
      activeName: null,
      records: []
    }
  },
  mounted () {
    this.loadData()
  },
  watch: {
    dataFilters: {
      immediate: false,
      handler () {
        this.loadData()
      }
    }
  },
  computed: {
    dataFilters () {
      let filters = []
      if (this.filters) {
        this.filters.forEach((item) => {
          let type = 'eq'
          if (item.isXref) {
            type = 'eqx'
          }
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              if (type === 'eq' || (type === 'eqx' && parseInt(this.getModel()[item.attribute]) !== 0)) {
                filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
              }
            }
          } else if (item.type === 'constant' && item.alias) {
            filters.push(`${item.alias},${type},${item.attribute}`)
          } else if (item.type === 'current_user' && item.alias) {
            filters.push(`${item.alias},${type},${this.$store.getters['Authorization/userId']}`)
          }
        })
      }
      return filters
    }
  },
  methods: {
    async getRegistryData (entityId) {
      let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${entityId}/data?limit=${this.limit}&
      ${this.dataFilters.map((filter, index) => `filter[${index}]=${filter}`).join('&')}`)
      return data.data.data
    },
    async loadData () {
      if (!this.source.type || !this.source.titleField || !this.source.contentField) {
        return false
      }
      switch (this.source.type) {
        case 'registry':
          let records = await this.getRegistryData(this.source.entityId)
          this.records = records.map((item) => {
            return {
              title: item[this.source.titleField],
              content: item[this.source.contentField]
            }
          })
          break
      }
    }
  }
}
</script>

<style>
</style>
