export default {
  // inject: ['getDashboardComponents', 'getModel'],
  inject: {
    getDashboardComponents: {
      default: () => {}
    },
    getModel: {
      default: () => {}
    },
    getCard: {
      default: () => {}
    }
  },
  props: {
    CSS: {
      type: String,
      description: 'CSS стили'
    },
    readonly: {
      description: 'Заблокировать',
      type: Boolean
    }
  },
  methods: {
    getProperties () {
      let properties = Object.assign({}, this.$vnode.componentOptions.Ctor.options.props)
      for (let property in properties) {
        if (properties.hasOwnProperty(property)) {
          if (properties[property].frozen) {
            delete properties[property]
          }
        }
      }
      return properties
    }
  }
}
